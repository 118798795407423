<template>
  <div class="price-bloc-data">
    <div class="history-tag-listing">
      <h2>{{ $t('product_page.price_history.price_history') }}</h2>
      <div class="history-tag-bottle">
        <p :style="`color: white; border-color: white;`">
          {{ getProduct.ticker }}
        </p>
        <h4>{{ getProduct.title }}</h4>
      </div>
      <div v-for="(tag, n) in getTags" :key="n" class="history-tag-bottle">
        <p :style="`color: ${tag.color}; border-color: ${tag.color};`">
          {{ tag.name }}
        </p>
        <h4>{{ tag.title }}</h4>
        <button @click="removeTag(tag, true)">
          <img src="@/assets/svg/v2/close.svg" alt="Close" />
        </button>
      </div>
    </div>

    <div class="product-history-compare-bloc">
      <div
        @click.stop="$emit('dialogCompare', !displayDialogCompareBottle)"
        class="product-history-compare"
      >
        <img src="@/assets/svg/v2/products/compare.svg" alt="Compare" />
        {{ $t('product_page.price_history.compare') }}

        <el-drawer
          v-model="displayDialogCompareBottle"
          title=""
          :size="drawerSize"
          @close="closeCompareBottle()"
        >
          <compareBottle @addBottle="selectBottle" />
        </el-drawer>
      </div>

      <div class="composite-choice">
        <p>
          {{ $t('product_page.price_history.composite.display_composite') }}
        </p>
        <el-switch
          v-model="toggle"
          :disabled="!getProduct.ticker_composite"
          style="--el-switch-on-color: #2667ff; --el-switch-off-color: #333333"
        />
      </div>
    </div>

    <div class="history-delay" v-if="getPriceHistoryInfos?.delay !== 0">
      <img src="@/assets/svg/v2/infos.svg" alt="Informations" />
      <p>
        {{ $t('product_page.price_history.delay') }}
        {{ getPriceHistoryInfos?.delay }}
        {{ $t('product_page.price_history.delay_2') }}
      </p>
      <div class="explore" @click="$router.push('/subscriptions')">
        <h3>{{ $t('settings.subscriptions.explore') }}</h3>
      </div>
    </div>

    <div
      class="hc-line"
      v-loading="chartLoading || currencyLoading"
      element-loading-background="rgba(34, 34, 34, 0.8)"
    >
      <div class="hc" v-if="chartLoading"></div>
      <highcharts
        v-else-if="getPriceHistory.length > 0"
        :constructorType="'stockChart'"
        class="hc"
        :options="chartOptions"
        ref="chart"
      ></highcharts>
      <!-- :reset="propsResetHCLine" -->
      <div class="hc" v-else-if="!chartLoading && getPriceHistory.length == 0">
        <p>{{ $t('product_page.price_history.no_recent_prices') }}</p>
      </div>

      <div class="product-history-infos-bloc">
        <div class="product-history-chart-options-bloc">
          <!-- <div class="product-history-chart-choice">
            <button :class="{ isSelected: type_of_chart === 0 }">
              <img src="@/assets/svg/v2/products/line.svg" alt="Line chart" />
              <span>
                {{ $t('product_page.price_history.line_chart') }}
              </span>
            </button> -->
          <!-- <button :class="{ 'isSelected': type_of_chart === 2 }">
              <img src="@/assets/svg/v2/products/candlesticks.svg" alt="Candlesticks chart" />
              <span>
                {{ $t('product_page.price_history.candlesticks_chart') }}
              </span>
            </button> -->
          <!-- </div> -->
          <div class="high-low-perf">
            <div class="high-low-perf-header">
              {{ getProduct.ticker }}
            </div>
            <div class="high-low-perf-body">
              <div class="last-price">
                <div class="last-price-title">
                  {{ $t('portfolio.last_price') }}
                </div>
                <div class="last-price-price">
                  <h5>{{ getLastPrice().price }}</h5>
                  <p>{{ getLastPrice().date }}</p>
                </div>
              </div>
              <div class="perf">
                <div class="perf-title">
                  {{ $t('product_page.price_history.perf') }}
                </div>
                <div class="perf-price">
                  <h5
                    :class="{
                      negative: getPerf().perf < 0,
                      positive: getPerf().perf > 0,
                    }"
                  >
                    {{ getPerf().perf }}%
                  </h5>
                  <p>
                    {{ $t('product_page.price_history.since') }}
                    {{ getPerf().date }}
                  </p>
                </div>
              </div>
              <div class="high">
                <div class="high-title">
                  <h4>{{ $t('product_page.price_history.high') }}</h4>
                </div>
                <div class="high-price">
                  <h5>{{ getMax().max }}</h5>
                  <p>{{ getMax().date }}</p>
                </div>
              </div>
              <div class="low">
                <div class="low-title">
                  {{ $t('product_page.price_history.low') }}
                </div>
                <div class="low-price">
                  <h5>{{ getMin().min }}</h5>
                  <p>{{ getMin().date }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="chart-options">
            <h4>{{ $t('product_page.price_history.chart_options') }}</h4>

            <div>
              <h5>{{ $t('product_page.price_history.current_view') }}</h5>
              <p v-if="dataGroupingType === 'month'">
                {{ $t('product_page.price_history.month') }}
              </p>
              <p v-else-if="dataGroupingType === 'year'">
                {{ $t('product_page.price_history.year') }}
              </p>
              <p v-else-if="dataGroupingType === 'week'">
                {{ $t('product_page.price_history.week') }}
              </p>
              <p v-else-if="dataGroupingType === 'day'">
                {{ $t('product_page.price_history.day') }}
              </p>
            </div>

            <div>
              <h5>{{ $t('product_page.price_history.currency') }}</h5>
              <el-select
                v-model="selectCurrency"
                effect="dark"
                class="select-currency"
                :disabled="getTags?.length > 0"
              >
                <el-option
                  v-for="item in getLists?.currency"
                  :key="item.currency"
                  :label="item.currency"
                  :value="item.currency"
                />
              </el-select>
            </div>
            <div>
              <h5>{{ $t('product_page.price_history.fees') }}</h5>
              <div>
                <p v-if="withFees">{{ $t('commons.enabled') }}</p>
                <p v-else>{{ $t('commons.disabled') }}</p>
                <el-checkbox
                  v-model="withFees"
                  text-color="#FFFFFF"
                  :disabled="getTags?.length > 0"
                />
              </div>
            </div>
            <el-divider class="divider-chart-options" />
            <div>
              <h5>
                {{ $t('product_page.price_history.average') }}
                <el-tooltip
                  effect="light"
                  :content="currentView"
                  placement="top-start"
                >
                  <img
                    class="info-average"
                    src="@/assets/svg/v2/infos.svg"
                    alt="Informations"
                  />
                </el-tooltip>
              </h5>
              <div>
                <p v-if="averageCheck">{{ $t('commons.enabled') }}</p>
                <p v-else>{{ $t('commons.disabled') }}</p>
                <el-checkbox
                  v-model="averageCheck"
                  text-color="#FFFFFF"
                  :disabled="getTags.length > 0 || isBase100"
                />
              </div>
            </div>
            <div>
              <h5 class="high-price-color">
                + {{ $t('product_page.price_history.high') }}
              </h5>
              <div>
                <p v-if="highCheck">{{ $t('commons.enabled') }}</p>
                <p v-else>{{ $t('commons.disabled') }}</p>
                <el-checkbox
                  v-model="highCheck"
                  text-color="#FFFFFF"
                  :disabled="getTags.length > 0 || isBase100"
                />
              </div>
            </div>
            <div>
              <h5 class="low-price-color">
                + {{ $t('product_page.price_history.low') }}
              </h5>
              <div>
                <p v-if="lowCheck">{{ $t('commons.enabled') }}</p>
                <p v-else>{{ $t('commons.disabled') }}</p>
                <el-checkbox
                  v-model="lowCheck"
                  text-color="#FFFFFF"
                  :disabled="getTags.length > 0 || isBase100"
                />
              </div>
            </div>
            <div>
              <h5>{{ $t('product_page.price_history.base_100') }}</h5>
              <div>
                <p v-if="base100Check">{{ $t('commons.enabled') }}</p>
                <p v-else>{{ $t('commons.disabled') }}</p>
                <el-checkbox
                  v-model="base100Check"
                  text-color="#FFFFFF"
                  :disabled="getTags.length > 0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { filter, find, findIndex } from 'lodash'
import { ElMessage } from 'element-plus'
import m from 'moment'
import selectRandomColor from '@/components/modules/charts/randomColorGenerator'
import compareBottle from '@/components/modules/dialogs/compareBottle.vue'

export default {
  props: {
    reset: {
      type: Number,
      required: true,
      default: 0,
    },
    fees: {
      type: Number,
      require: true,
      default: 1,
    },
    dialogCompareBottle: {
      type: Boolean,
      required: false,
      default: false,
    },
    type_of_chart: {
      type: Number,
      require: true,
      default: 0,
    },
  },
  components: {
    compareBottle,
  },
  emits: [
    'dialogCompare',
    'currency',
    'fees',
    'tagPriceHistory',
    'type_of_chart',
  ],
  data() {
    return {
      displayCompositeTicker: false,
      displayDialogCompareBottle: false,

      line_chart_options: [],
      options_base100_fees: [],

      tags: [],

      maxTimestamp: null,
      minTimestamp: null,

      lowCheck: null,
      highCheck: null,
      averageCheck: null,
      base100Check: null,
      perf: null,
      withFees: true,
      selectCurrency: 'EUR',
      dataGroupingType: 'day',

      chartLoading: true,
      currencyLoading: false,

      chartOptions: {
        accessibility: {
          description: 'Bottle price history',
        },
        tooltip: {
          shared: true,
          split: false,
          distance: 25,
          backgroundColor: '#333333',
          pointFormat: `<span style="color:{series.color}">
            {series.name}: <b>{point.y:.0f}</b></span><br/>`,
          style: {
            color: '#FFFFFF',
          },
        },
        credits: {
          enabled: false,
        },
        chart: {
          backgroundColor: '#333333',
          events: {
            load: function () {
              for (let i = 1; i < this.series[0].data.length; i++) {
                if (this.series[0].data[i].y > this.series[0].data[i - 1].y) {
                  this.series[0].data[i].update(
                    {
                      color: '#A3D1F9CC',
                    },
                    false
                  )
                }
              }
              this.redraw()
            },
          },
        },
        rangeSelector: {
          buttonTheme: {
            fill: '#444444',
            stroke: 'none',
            'stroke-width': 0,
            r: 8,
            style: {
              color: '#FFF',
              fontWeight: 'bold',
            },
            states: {
              select: {
                fill: '#666666',
                style: {
                  color: 'white',
                },
              },
            },
          },
          inputBoxBorderColor: 'none',
          inputBoxWidth: 120,
          inputBoxHeight: 18,
          inputStyle: {
            color: '#FFF',
            fontWeight: 'bold',
          },
          labelStyle: {
            color: 'silver',
            fontWeight: 'bold',
          },
          buttons: [
            {
              type: 'month',
              count: 1,
              text: '1M',
            },
            {
              type: 'month',
              count: 3,
              text: '3M',
            },
            {
              type: 'month',
              count: 6,
              text: '6M',
            },
            {
              type: 'ytd',
              text: 'YTD',
            },
            {
              type: 'year',
              count: 1,
              text: '1Y',
            },
            {
              type: 'all',
              text: 'MAX',
              forced: true,
            },
          ],
        },
        plotOptions: {
          column: {
            grouping: false,
          },
        },
        series: [
          {
            type: 'column',
            name: 'Volume',
            isbn: 'isbn',
            data: [],
            color: '#00000020',
            cursor: 'pointer',
            accessibility: {
              description: 'Volume',
            },
            showInNavigator: false,
          },
          {
            type: 'line',
            name: 'Price',
            isbn: 'isbn',
            data: [],
            color: '#00000020',
            cursor: 'pointer',
            accessibility: {
              description: 'Price',
            },
            showInNavigator: true,
            dataGrouping: {
              approximation: 'min',
              enabled: true,
            },
          },
        ],
        yAxis: [
          {
            labels: {
              align: 'left',
            },
            height: '100%',
            lineWidth: 0,
            gridLineColor: '#FFFFFF3D',
            resize: {
              enabled: true,
              lineDashStyle: 'Solid',
              lineColor: '#ffffff',
            },
          },
          {
            labels: {
              enabled: false,
              x: -5,
              y: 0,
            },
            top: '60%',
            height: '40%',
            offset: 0,
            lineWidth: 0,
            visible: false,
          },
        ],
        xAxis: [
          {
            visible: true,
            type: 'datetime',
            labels: {
              enabled: false,
            },
            lineWidth: 0,
            tickWidth: 0,
            events: {
              setExtremes: (e) => this.handleRangeChange(e),
              afterSetExtremes: (event) => {
                if (event.target.series[0].currentDataGrouping !== undefined) {
                  const dataGrouping =
                    event.target.series[0].currentDataGrouping.unitName
                  this.dataGroupingType = dataGrouping
                }
              },
            },
            plotBands: [],
          },
          {
            visible: false,
            gridLineWidth: 0,
          },
        ],
      },

      search: '',
      last_pending_search: '',
      payload: {
        search: null,
        spirit_type: 14,
      },
    }
  },
  async created() {
    this.$store.commit('PRICE_HISTORY', [])
    this.$store.commit('RESET_TAGS', [])

    await this.getClient().then(() => {
      this.selectCurrency = this.getUserDetails?.preferences?.currency || 'EUR'
    })
  },
  watch: {
    getPriceHistory() {
      this.resetLineChartData()
    },
    reset() {
      this.resetLineChartData()
    },
    fees() {
      this.resetLineChartData()
    },
    async selectCurrency() {
      this.currencyLoading = true
      this.$emit('currency', this.selectCurrency)

      const payload = {
        currency: this.selectCurrency,
      }

      await this.choiceCurrency(payload).then(async () => {
        await this.getClient().then(() => {
          this.currencyLoading = false
        })
      })
    },
    lowCheck() {
      if (this.lowCheck) {
        this.line_chart_options.push('Low')
      } else {
        const indexLow = findIndex(this.line_chart_options, (e) => {
          return e === 'Low'
        })

        this.line_chart_options.splice(indexLow, 1)
      }
    },
    highCheck() {
      if (this.highCheck) {
        this.line_chart_options.push('High')
      } else {
        const indexHigh = findIndex(this.line_chart_options, (e) => {
          return e === 'High'
        })

        this.line_chart_options.splice(indexHigh, 1)
      }
    },
    averageCheck() {
      if (this.averageCheck && !this.line_chart_options.includes('Average')) {
        this.line_chart_options.push('Average')
      } else {
        const indexAverage = findIndex(this.line_chart_options, (e) => {
          return e === 'Average'
        })

        this.line_chart_options.splice(indexAverage, 1)
      }
    },
    base100Check() {
      if (this.base100Check) {
        this.options_base100_fees.push('Base 100')
      } else {
        const indexBase = findIndex(this.options_base100_fees, (e) => {
          return e === 'Base 100'
        })

        this.options_base100_fees.splice(indexBase, 1)
      }
    },
    withFees() {
      this.$emit('fees', this.withFees)
    },
    getPriceHistoryTagsLength() {
      this.getTags.forEach((tag) => {
        const newLineAverage = {
          type: 'line',
          name: tag.name,
          isbn: tag.isbn,
          data: [],
          color: tag.color,
          lineWidth: 2,
        }

        const newVolume = {
          type: 'column',
          name: `Volume ${tag.name}`,
          isbn: tag.isbn,
          data: [],
          color: tag.color,
          yAxis: 1,
          cursor: 'pointer',
          accessibility: {
            description: `Volume ${tag.name}`,
          },
        }

        const priceHistoryTag = this.getPriceHistoryTags.find(
          (e) => e.isbn === tag.isbn
        )

        if (priceHistoryTag) {
          const dataTag = priceHistoryTag.data

          const duplicateLineAverage = this.chartOptions.series.find(
            (serie) => serie.name === newLineAverage.name
          )
          const duplicateVolume = this.chartOptions.series.find(
            (serie) => serie.name === newVolume.name
          )

          if (!duplicateLineAverage) {
            if (this.fees === 0) {
              if (this.getPriceHistoryTags.length !== 0) {
                for (const date in dataTag) {
                  const data = [
                    dataTag[date].x,
                    dataTag[date].price_without_fees,
                  ]
                  newLineAverage.data.push(data)
                }
              }
            } else if (this.fees === 1) {
              if (this.getPriceHistoryTags.length !== 0) {
                for (const date in dataTag) {
                  const data = [dataTag[date].x, dataTag[date].price]
                  newLineAverage.data.push(data)
                }
              }
            }

            this.chartOptions.series.push(newLineAverage)
          }

          if (!duplicateVolume) {
            for (const date in dataTag) {
              const volume = [dataTag[date].x, dataTag[date].size]
              newVolume.data.push(volume)
            }

            this.chartOptions.series.push(newVolume)
          }
        }
      })
      const lowestTimestamp = this.getPriceHistoryTags
        .map((tag) => tag.data[0].x)
        .sort((a, b) => a - b)[0]
      const highestTimestamp = this.getPriceHistoryTags
        .map((tag) => tag.data[tag.data.length - 1].x)
        .sort((a, b) => b - a)[0]
      this.$refs.chart.chart.xAxis[0].setExtremes(
        lowestTimestamp,
        highestTimestamp
      )
    },
    line_chart_options: {
      handler() {
        const findHigh = find(this.chartOptions.series, { name: 'High' })
        const findAverage = find(this.chartOptions.series, { name: 'Average' })
        const findLow = find(this.chartOptions.series, { name: 'Low' })

        if (this.fees === 0) {
          if (this.line_chart_options.includes('High')) {
            this.resetBase100()

            if (!findHigh) {
              const newLineHigh = {
                type: 'line',
                name: 'High',
                data: [],
                color: 'green',
                lineWidth: 2,
                dataGrouping: {
                  approximation: 'high',
                  enabled: true,
                },
              }
              if (this.getPriceHistory.length !== 0) {
                for (const date in this.getPriceHistory) {
                  const data = [
                    this.getPriceHistory[date].x,
                    this.getPriceHistory[date].price_without_fees,
                  ]
                  newLineHigh.data.push(data)
                }

                this.chartOptions.series.push(newLineHigh)
              }
            }
          } else {
            this.chartOptions.series = filter(
              this.chartOptions.series,
              function (data) {
                return data.name !== 'High'
              }
            )
          }

          if (this.line_chart_options.includes('Average')) {
            this.resetBase100()

            if (!findAverage) {
              const newLineAverage = {
                type: 'line',
                name: 'Average',
                data: [],
                color: '#000',
                lineWidth: 2,
              }

              if (this.getPriceHistory.length !== 0) {
                let currentYear = null
                let startFrom = null
                let endTo = null
                this.chartOptions.xAxis[0].plotBands = []

                for (const date in this.getPriceHistory) {
                  const data = [
                    this.getPriceHistory[date].x,
                    this.getPriceHistory[date].price_without_fees,
                  ]

                  if (
                    Number(m(data[0]).format('YYYY')) % 2 === 0 &&
                    !startFrom
                  ) {
                    startFrom = this.getPriceHistory[date].x
                    currentYear = Number(m(data[0]).format('YYYY'))
                  } else if (Number(m(data[0]).format('YYYY')) % 2 !== 0) {
                    const y = Number(m(data[0]).format('YYYY'))

                    if (y !== currentYear && currentYear) {
                      endTo = this.getPriceHistory[date - 1].x

                      const bandPerYear = {
                        color: '#ffffff11',
                        from: startFrom,
                        to: endTo,
                      }

                      currentYear = null
                      this.chartOptions.xAxis[0].plotBands.push(bandPerYear)
                      startFrom = null
                      endTo = null
                    }
                  } else if (Number(date) === this.getPriceHistory.length - 1) {
                    endTo = this.getPriceHistory[date].x

                    const bandPerYear = {
                      color: '#ffffff11',
                      from: startFrom,
                      to: endTo,
                    }

                    currentYear = null
                    this.chartOptions.xAxis[0].plotBands.push(bandPerYear)
                    startFrom = null
                    endTo = null
                  }

                  newLineAverage.data.push(data)
                }

                this.chartOptions.series.push(newLineAverage)
              }
            }
          } else {
            this.chartOptions.series = filter(
              this.chartOptions.series,
              function (data) {
                return data.name !== 'Average'
              }
            )
          }

          if (this.line_chart_options.includes('Low')) {
            this.resetBase100()

            if (!findLow) {
              const newLineLow = {
                type: 'line',
                name: 'Low',
                data: [],
                color: 'red',
                lineWidth: 2,
                dataGrouping: {
                  approximation: 'low',
                  enabled: true,
                },
              }

              if (this.getPriceHistory.length !== 0) {
                // let currentYear = null;
                // let startFrom = null;
                // let endTo = null;
                // this.chartOptions.xAxis[0].plotBands = [];

                for (const date in this.getPriceHistory) {
                  const data = [
                    this.getPriceHistory[date].x,
                    this.getPriceHistory[date].min_without_fees,
                  ]

                  // if (Number(m(data[0]).format('YYYY')) % 2 === 0 && !startFrom) { // 2018

                  //   startFrom = this.getPriceHistory[date].x;
                  //   currentYear = Number(m(data[0]).format('YYYY'));

                  //   } else if (Number(m(data[0]).format('YYYY')) % 2 !== 0) { // 2019

                  //     const y = Number(m(data[0]).format('YYYY'));

                  //     if (y !== currentYear && currentYear) {
                  //       endTo = this.getPriceHistory[date - 1].x;

                  //       const bandPerYear = {
                  //         color: '#ffffff11',
                  //         from: startFrom,
                  //         to: endTo
                  //       }

                  //       currentYear = null;
                  //       this.chartOptions.xAxis[0].plotBands.push(bandPerYear);
                  //       startFrom = null;
                  //       endTo = null;
                  //     }
                  //   } else if (Number(date) === this.getPriceHistory.length - 1) {
                  //     endTo = this.getPriceHistory[date].x;

                  //     const bandPerYear = {
                  //       color: '#ffffff11',
                  //       from: startFrom,
                  //       to: endTo
                  //     }

                  //     currentYear = null;
                  //     this.chartOptions.xAxis[0].plotBands.push(bandPerYear);
                  //     startFrom = null;
                  //     endTo = null;
                  //   }

                  newLineLow.data.push(data)
                }

                this.chartOptions.series.push(newLineLow)
              }
            }
          } else {
            this.chartOptions.series = filter(
              this.chartOptions.series,
              function (data) {
                return data.name !== 'Low'
              }
            )
          }

          if (
            this.line_chart_options.length === 0 &&
            !this.options_base100_fees.includes('Base 100')
          ) {
            this.resetLineChartData()
          }
        } else if (this.fees === 1) {
          if (this.line_chart_options.includes('High')) {
            this.resetBase100()

            if (!findHigh) {
              const newLineHigh = {
                type: 'line',
                name: 'High',
                data: [],
                color: 'green',
                lineWidth: 2,
                dataGrouping: {
                  approximation: 'high',
                  enabled: true,
                },
              }

              if (this.getPriceHistory.length !== 0) {
                for (const date in this.getPriceHistory) {
                  const data = [
                    this.getPriceHistory[date].x,
                    this.getPriceHistory[date].max,
                  ]
                  newLineHigh.data.push(data)
                }

                this.chartOptions.series.push(newLineHigh)
              }
            }
          } else {
            this.chartOptions.series = filter(
              this.chartOptions.series,
              function (data) {
                return data.name !== 'High'
              }
            )
          }

          if (this.line_chart_options.includes('Average')) {
            this.resetBase100()

            if (!findAverage) {
              const newLineAverage = {
                type: 'line',
                name: 'Average',
                data: [],
                color: '#ffffff99',
                lineWidth: 2,
              }

              if (this.getPriceHistory.length !== 0) {
                for (const date in this.getPriceHistory) {
                  const data = [
                    this.getPriceHistory[date].x,
                    this.getPriceHistory[date].price,
                  ]
                  newLineAverage.data.push(data)
                }

                this.chartOptions.series.push(newLineAverage)
              }
            }
          } else {
            this.chartOptions.series = filter(
              this.chartOptions.series,
              function (data) {
                return data.name !== 'Average'
              }
            )
          }

          if (this.line_chart_options.includes('Low')) {
            this.resetBase100()

            if (!findLow) {
              const newLineLow = {
                type: 'line',
                name: 'Low',
                data: [],
                color: 'red',
                dataGrouping: {
                  approximation: 'low',
                  enabled: true,
                },
              }

              if (this.getPriceHistory.length !== 0) {
                for (const date in this.getPriceHistory) {
                  const data = [
                    this.getPriceHistory[date].x,
                    this.getPriceHistory[date].min,
                  ]
                  newLineLow.data.push(data)
                }

                this.chartOptions.series.push(newLineLow)
              }
            }
          } else {
            this.chartOptions.series = filter(
              this.chartOptions.series,
              function (data) {
                return data.name !== 'Low'
              }
            )
          }

          if (
            this.line_chart_options.length === 0 &&
            !this.options_base100_fees.includes('Base 100')
          ) {
            this.resetLineChartData()
          }
        }
      },
      deep: true,
    },
    options_base100_fees: {
      handler() {
        if (this.options_base100_fees.includes('Base 100')) {
          this.getTags.forEach((tag) => {
            this.removeTag(tag)
            this.$emit('tagPriceHistory', tag.isbn)
          })

          const diviser = this.getPriceHistory[0]?.price

          const newLineBase100 = {
            type: 'line',
            name: 'Base 100',
            data: [],
            color: '#fff',
            lineWidth: 2,
          }

          if (this.getPriceHistory.length !== 0) {
            this.line_chart_options = []

            for (const date in this.getPriceHistory) {
              const price100 = parseInt(
                Math.round((this.getPriceHistory[date].price / diviser) * 100)
              )
              const data = [this.getPriceHistory[date].x, price100]
              newLineBase100.data.push(data)
            }

            this.chartOptions.series.push(newLineBase100)
          }
        } else {
          this.chartOptions.series = filter(
            this.chartOptions.series,
            function (data) {
              return data.name !== 'Base 100'
            }
          )
        }

        if (
          this.line_chart_options.length === 0 &&
          this.options_base100_fees.length === 0
        ) {
          this.resetLineChartData()
        }
      },
      deep: true,
    },
    getSearchPending() {
      if (this.payload.search !== this.last_pending_search.search) {
        this.last_pending_search.search = this.payload.search

        this.autoSearchAuth(this.payload)
      }
    },
    dialogCompareBottle() {
      if (this.dialogCompareBottle) {
        this.displayDialogCompareBottle = true
      } else {
        this.displayDialogCompareBottle = false
      }
    },
  },
  computed: {
    ...mapGetters({
      getPriceHistory: 'getPriceHistory',
      getPriceHistoryTags: 'getPriceHistoryTags',
      getPriceHistoryInfos: 'getPriceHistoryInfos',
      getProduct: 'getProduct',
      getCurrency: 'getCurrency',
      getLists: 'getLists',
      getAuthStatus: 'getIsAuth',
      getSearchPending: 'getSearchPending',
      getTags: 'getTags',
      getUserDetails: 'getUserDetails',
    }),
    getPriceHistoryTagsLength() {
      return this.getPriceHistoryTags.length
    },
    isBase100() {
      if (this.options_base100_fees.includes('Base 100')) {
        return true
      }

      return false
    },
    toggle: {
      get() {
        return this.displayCompositeTicker
      },
      set() {
        if (!this.displayCompositeTicker) {
          this.$emit('type_of_chart', 1)
          this.displayCompositeTicker = true
        }
      },
    },
    currentView() {
      let label = ''

      if (this.dataGroupingType === 'day') {
        label =
          this.$t('product_page.price_history.average') +
          ' ' +
          this.$t('product_page.price_history.day')
      } else if (this.dataGroupingType === 'week') {
        label =
          this.$t('product_page.price_history.average') +
          ' ' +
          this.$t('product_page.price_history.week')
      } else if (this.dataGroupingType === 'month') {
        label =
          this.$t('product_page.price_history.average') +
          ' ' +
          this.$t('product_page.price_history.month')
      } else if (this.dataGroupingType === 'year') {
        label =
          this.$t('product_page.price_history.average') +
          ' ' +
          this.$t('product_page.price_history.year')
      }

      return label
    },
    drawerSize() {
      if (window.innerWidth < 500) {
        return '100%'
      }
      return '50%'
    },
  },
  methods: {
    ...mapActions({
      autoSearchAuth: 'autocompleteSearchAuth',
      choiceCurrency: 'choiceCurrency',
      getClient: 'getAccountDetails',
    }),
    getPerf() {
      let startDate = null
      let endDate = null

      let priceStartDate = null
      let priceEndDate = null

      if (this.getPriceHistory.length > 0) {
        startDate = this.getPriceHistory[0].date
        priceStartDate = this.getPriceHistory[0].price

        endDate = this.getPriceHistory[this.getPriceHistory.length - 1].date
        priceEndDate =
          this.getPriceHistory[this.getPriceHistory.length - 1].price
      } else if (this.getPriceHistoryInfos?.should_subscribe) {
        this.chartLoading = false
      }

      if (!this.minTimestamp) {
        this.minTimestamp = startDate
      }

      if (!this.maxTimestamp) {
        this.maxTimestamp = endDate
      }

      if (this.minTimestamp !== null && this.maxTimestamp !== null) {
        const findStartPrice = filter(this.getPriceHistory, (point) => {
          return point.date >= this.minTimestamp
        })

        const findEndPrice = filter(this.getPriceHistory, (point) => {
          return point.date <= this.maxTimestamp
        })

        if (findStartPrice.length > 0 && findEndPrice.length > 0) {
          const perfs = (
            ((findEndPrice[findEndPrice.length - 1].price -
              findStartPrice[0].price) /
              findStartPrice[0].price) *
            100
          ).toFixed(2)
          this.perf = perfs
          startDate = this.minTimestamp
        }
      }

      if (startDate) {
        startDate = m(startDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
        this.chartLoading = false
      }

      return {
        perf: this.perf,
        date: startDate,
        infos: {
          start: startDate,
          startPrice: priceStartDate,
          end: endDate,
          endPrice: priceEndDate,
        },
      }
    },
    getMin() {
      let minValue = null
      let minDate = null

      if (this.minTimestamp !== null) {
        for (const point in this.getPriceHistory) {
          const data = {
            date: this.getPriceHistory[point].date,
            min: this.getPriceHistory[point].min,
          }

          if (data.date >= this.minTimestamp) {
            if (minValue === null && data.min !== null) {
              minValue = data.min
              minDate = m(data.date, 'YYYY-MM-DD').format('DD/MM/YYYY')
            } else {
              if (minValue > data.min) {
                minValue = data.min
                minDate = m(data.date, 'YYYY-MM-DD').format('DD/MM/YYYY')
              }
            }
          }
        }
      } else {
        for (const point in this.getPriceHistory) {
          const data = {
            date: this.getPriceHistory[point].date,
            min: this.getPriceHistory[point].min,
          }

          if (minValue === null && data.min !== null) {
            minValue = data.min
            minDate = m(data.date, 'YYYY-MM-DD').format('DD/MM/YYYY')
          } else {
            if (minValue > data.min) {
              minValue = data.min
              minDate = m(data.date, 'YYYY-MM-DD').format('DD/MM/YYYY')
            }
          }
        }
      }

      return { min: minValue?.toFixed(0), date: minDate }
    },
    getMax() {
      let maxValue = null
      let maxDate = null

      if (this.maxTimestamp !== null) {
        for (const point in this.getPriceHistory) {
          const data = {
            date: this.getPriceHistory[point].date,
            max: this.getPriceHistory[point].max,
          }

          if (data.date <= this.maxTimestamp) {
            if (maxValue === null && data.max !== null) {
              maxValue = data.max
              maxDate = m(data.date, 'YYYY-MM-DD').format('DD/MM/YYYY')
            } else {
              if (maxValue < data.max) {
                maxValue = data.max
                maxDate = m(data.date, 'YYYY-MM-DD').format('DD/MM/YYYY')
              }
            }
          }
        }
      } else {
        for (const point in this.getPriceHistory) {
          const data = {
            date: this.getPriceHistory[point].date,
            max: this.getPriceHistory[point].max,
          }

          if (maxValue === null && data.max !== null) {
            maxValue = data.max
            maxDate = m(data.date, 'YYYY-MM-DD').format('DD/MM/YYYY')
          } else {
            if (maxValue < data.max) {
              maxValue = data.max
              maxDate = m(data.date, 'YYYY-MM-DD').format('DD/MM/YYYY')
            }
          }
        }
      }

      return { max: maxValue?.toFixed(0), date: maxDate }
    },
    getLastPrice() {
      const data = {
        date: null,
        price: null,
      }
      if (this.getPriceHistory.length > 0) {
        data.date = m(
          this.getPriceHistory[this.getPriceHistory.length - 1].date,
          'YYYY-MM-DD'
        ).format('DD/MM/YYYY')
        if (this.fees === 0) {
          data.price =
            this.getPriceHistory[
              this.getPriceHistory.length - 1
            ].price_without_fees
        } else {
          data.price =
            this.getPriceHistory[this.getPriceHistory.length - 1].price
        }
      } else {
        data.date = 'N/A'
      }

      return data
    },
    handleRangeChange(e) {
      const minDate = m(e.min, 'x').format('YYYY-MM-DD')
      const maxDate = m(e.max, 'x').format('YYYY-MM-DD')
      this.minTimestamp = minDate
      this.maxTimestamp = maxDate
      this.getPerf()
    },
    resetLineChartData() {
      const indexLine = findIndex(this.chartOptions.series, (e) => {
        return e.type === 'line'
      })

      if (indexLine !== -1) {
        this.chartOptions.series.splice(indexLine, 1)
      }

      if (this.getPriceHistory.length !== 0) {
        const newLineAverage = {
          type: 'line',
          name: 'Average',
          data: [],
          color: '#ffffff',
          lineWidth: 2,
        }

        const newVolume = {
          type: 'column',
          name: 'Volume',
          isbn: 'isbn',
          data: [],
          color: '#ffffff99',
          yAxis: 1,
          cursor: 'pointer',
          accessibility: {
            description: 'Volume',
          },
        }

        if (this.fees === 0) {
          // let currentYear = null;
          // let startFrom = null;
          // let endTo = null;
          // this.chartOptions.xAxis[0].plotBands = [];

          if (this.getPriceHistory.length !== 0) {
            for (const date in this.getPriceHistory) {
              const data = [
                this.getPriceHistory[date].x,
                this.getPriceHistory[date].price_without_fees,
              ]

              // if (Number(m(data[0]).format('YYYY')) % 2 === 0 && !startFrom) { // 2018

              //   startFrom = this.getPriceHistory[date].x;
              //   currentYear = Number(m(data[0]).format('YYYY'));

              //   } else if (Number(m(data[0]).format('YYYY')) % 2 !== 0) { // 2019

              //     const y = Number(m(data[0]).format('YYYY'));

              //     if (y !== currentYear && currentYear) {
              //       endTo = this.getPriceHistory[date - 1].x;

              //       const bandPerYear = {
              //         color: '#ffffff11',
              //         from: startFrom,
              //         to: endTo
              //       }

              //       currentYear = null;
              //       this.chartOptions.xAxis[0].plotBands.push(bandPerYear);
              //       startFrom = null;
              //       endTo = null;
              //     }
              //   } else if (Number(date) === this.getPriceHistory.length - 1) {
              //     endTo = this.getPriceHistory[date].x;

              //     const bandPerYear = {
              //       color: '#ffffff11',
              //       from: startFrom,
              //       to: endTo
              //     }

              //     currentYear = null;
              //     this.chartOptions.xAxis[0].plotBands.push(bandPerYear);
              //     startFrom = null;
              //     endTo = null;
              //   }

              newLineAverage.data.push(data)
            }
          }
        } else if (this.fees === 1) {
          if (this.getPriceHistory.length !== 0) {
            // let currentYear = null;
            // let startFrom = null;
            // let endTo = null;
            // this.chartOptions.xAxis[0].plotBands = [];

            for (const date in this.getPriceHistory) {
              const data = [
                this.getPriceHistory[date].x,
                this.getPriceHistory[date].price,
              ]

              // if (Number(m(data[0]).format('YYYY')) % 2 === 0 && !startFrom) { // 2018

              //   startFrom = this.getPriceHistory[date].x;
              //   currentYear = Number(m(data[0]).format('YYYY'));

              // } else if (Number(m(data[0]).format('YYYY')) % 2 !== 0) { // 2019

              //   const y = Number(m(data[0]).format('YYYY'));

              //   if (y !== currentYear && currentYear) {
              //     endTo = this.getPriceHistory[date - 1].x;

              //     const bandPerYear = {
              //       color: '#ffffff11',
              //       from: startFrom,
              //       to: endTo
              //     }

              //     currentYear = null;
              //     this.chartOptions.xAxis[0].plotBands.push(bandPerYear);
              //     startFrom = null;
              //     endTo = null;
              //   }
              // } else if (Number(date) === this.getPriceHistory.length - 1) {
              //   endTo = this.getPriceHistory[date].x;

              //   const bandPerYear = {
              //     color: '#ffffff11',
              //     from: startFrom,
              //     to: endTo
              //   }

              //   currentYear = null;
              //   this.chartOptions.xAxis[0].plotBands.push(bandPerYear);
              //   startFrom = null;
              //   endTo = null;
              // }
              newLineAverage.data.push(data)
            }
          }
        }

        this.chartOptions.series.push(newLineAverage)
        this.averageCheck = true
        this.line_chart_options.push('Average')

        for (const date in this.getPriceHistory) {
          const volume = [
            this.getPriceHistory[date].x,
            this.getPriceHistory[date].size,
          ]
          newVolume.data.push(volume)
        }
        this.chartOptions.series[0] = newVolume

        this.highCheck = false
        this.lowCheck = false

        this.currencyLoading = false

        const indexHigh = findIndex(this.chartOptions.series, (e) => {
          return e.name === 'High'
        })

        if (indexHigh !== -1) {
          this.chartOptions.series.splice(indexHigh, 1)
        }

        const indexHighOption = findIndex(this.line_chart_options, (e) => {
          return e === 'High'
        })

        if (indexHighOption !== -1) {
          this.line_chart_options.splice(indexHighOption, 1)
        }

        const indexLow = findIndex(this.chartOptions.series, (e) => {
          return e.name === 'Low'
        })

        if (indexLow !== -1) {
          this.chartOptions.series.splice(indexLow, 1)
        }

        const indexLowOption = findIndex(this.line_chart_options, (e) => {
          return e === 'Low'
        })

        if (indexLowOption !== -1) {
          this.line_chart_options.splice(indexLowOption, 1)
        }
      }
    },
    resetBase100() {
      if (this.options_base100_fees.includes('Base 100')) {
        const indexBase100 = findIndex(this.chartOptions.series, (e) => {
          return e.name === 'Base 100'
        })

        this.chartOptions.series.splice(indexBase100, 1)
        this.options_base100_fees.pop()
        this.highCheck = false
        this.lowCheck = false
      }
    },
    async selectBottle(bottle) {
      if (bottle.isbn !== this.getProduct.isbn) {
        const tag = {
          name: bottle.ticker_maitre,
          color: null,
          isbn: bottle.isbn,
          title: bottle.title,
          currency: this.selectCurrency,
          ticker_composite: bottle.ticker_composite,
          ticker_maitre: bottle.ticker_maitre,
        }

        tag.color = selectRandomColor(this.getTags)

        const findBottle = find(this.getTags, { isbn: tag.isbn })

        if (this.getTags.length < 6) {
          if (findBottle) {
            ElMessage({
              message: this.$t(
                'product_page.price_history.compare_dialog.same_bottle'
              ),
              type: 'warning',
              grouping: true,
              duration: 5000,
            })
          } else {
            this.$emit('tagPriceHistory', tag)
          }
        } else {
          ElMessage({
            message: this.$t('product_page.price_history.compare_dialog.limit'),
            type: 'warning',
            grouping: true,
            duration: 5000,
          })
        }
      } else {
        ElMessage({
          message: this.$t(
            'product_page.price_history.compare_dialog.same_bottle'
          ),
          type: 'warning',
          grouping: true,
          duration: 5000,
        })
      }
    },
    searchProducts(searchProducts, key) {
      this.search = searchProducts

      this.payload.search = searchProducts.value
      this.payload.spirit_type = searchProducts.spirit_type
      if (!this.payload.filters) {
        this.payload.filters = {
          spirit_type: null,
          country: null,
          age: null,
          vintage: null,
          single_cask: false,
        }
      }

      if (!this.getSearchPending['searchProduct']) {
        this.payload.type = key

        this.last_pending_search = searchProducts

        this.autoSearchAuth(this.payload)
      }
    },
    removeTag(tag, removeGlobal) {
      const indexTag = findIndex(this.getTags, (e) => {
        return e.name === tag.name
      })

      if (indexTag !== undefined) {
        const newChartOptionSeries = this.chartOptions.series.filter(
          (s) => s.isbn !== tag.isbn
        )
        this.chartOptions.series = newChartOptionSeries
        if (removeGlobal) {
          this.$store.commit('REMOVE_TAG', tag)
        }
        this.$store.commit('REMOVE_PRICE_HISTORY_TAGS', indexTag)
        this.$store.commit('REMOVE_PRICE_HISTORY_INFOS_TAGS', indexTag)

        this.$refs.chart.chart.redraw()
      }
    },
    closeCompareBottle() {
      this.$emit('closeCompare', false)
    },
  },
  expose: ['removeTag'],
}
</script>

<style lang="scss">
@import '@/assets/styles/root.scss';

.hc-line-options,
.options-bloc-hc,
.bloc-left-hc,
.statistiques-high,
.statistiques-low,
.statistiques-perf,
.options-bloc-type-line-hc,
.multi-ticker,
.tags-bloc,
.product-history-infos-bloc,
.high-low-perf,
.high-low-perf-header,
.high-low-perf-body,
.product-history-chart-choice,
.product-history-compare,
.product-history-compare-bloc,
.composite-choice,
.history-delay,
.explore {
  display: flex;
}

.hc-line {
  padding: 17px 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  .hc {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    height: 543px;
    width: 100%;
    border-radius: 12px;
    border: 1px solid $border;
    background: #333;

    p {
      color: #ffffff;
      text-align: center;
    }
  }
}

.product-history-infos-bloc {
  margin-left: 20px;
}

.hc-line-options {
  margin: 10px 0;
  margin-top: 20px;
  justify-content: space-between;

  .bloc-right {
    .statistiques {
      div {
        p {
          font-size: 13px;
        }

        .stats-identifier {
          width: 50px;
        }

        .high,
        .low,
        .perf {
          width: 40px;
        }

        .on {
          width: 25px;
        }
      }
    }
  }

  .options-bloc-hc {
    flex-direction: column;
    margin: 0 8px;
  }

  .options-bloc-type-line-hc {
    align-items: flex-start;
    margin-left: 22px;
  }
}

.negative {
  color: $alert !important;
}

.positive {
  color: #00c900 !important;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: $base-color;
}

.el-checkbox.is-bordered.is-checked,
.el-checkbox__input.is-checked .el-checkbox__inner {
  border-color: $base-color;
}

.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: $base-color;
}

.multi-ticker {
  .search-bloc {
    width: 50%;
    min-width: 350px;
  }

  .tags-bloc {
    margin-left: 20px;
    flex-wrap: wrap;

    .el-tag {
      margin: 0 5px;
      color: #fff;
      border-color: none;

      .el-tag__close {
        color: #fff;
      }
    }
  }
}

.high-low-perf {
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid $border;
  background: linear-gradient(
    180deg,
    #2667ff -258.33%,
    rgba(38, 103, 255, 0) 63.77%
  );
  margin-top: 0;
  width: 445px;
  height: fit-content;
  justify-content: center;
  align-items: center;

  .high-low-perf-header {
    color: white;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 15px;
  }

  .high-low-perf-body {
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;

    div {
      display: flex;
      justify-content: space-between;
      margin: 0 8px;
    }

    .high .high-title,
    .low .low-title,
    .perf .perf-title,
    .last-price .last-price-title {
      color: white;
      margin-bottom: 5px;
      margin-top: 5px;
      font-weight: 600;
    }

    .high-price,
    .low-price,
    .perf-price,
    .last-price-price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 66%;

      h5 {
        color: white;
        font-size: 16px;
        font-weight: 600;
      }

      p {
        color: white;
        font-size: 15px;
        margin-left: 5px;
      }
    }
  }
}

.chart-options {
  border-radius: 12px;
  border: 1px solid $border;
  background: #333;
  margin-top: 10px;
  padding: 14px 20px;

  .high-price-color {
    color: green;
  }

  .low-price-color {
    color: red;
  }

  h4 {
    color: white;
    margin-bottom: 25px;
    font-weight: 500;
  }

  .divider-chart-options {
    margin: 15px 0;
  }

  div {
    display: flex;
    color: white;
    justify-content: space-between;
    align-items: center;

    h5 {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;

      .info-average {
        margin-left: 5px;
      }
    }

    .select-currency {
      color: white;

      div div div {
        padding-right: 0;
      }
    }

    div {
      p {
        margin-right: 10px;
      }
    }
  }
}

.el-select__selection {
  width: 31px;
}

.product-history-compare-bloc {
  justify-content: space-between;
  align-items: center;

  .product-history-compare {
    position: relative;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid #2667ff;
    background: #2667ff;
    width: 210px;
    height: 36px;
    font-size: 15px;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 5px;
    }
  }

  .composite-choice {
    align-items: center;

    p {
      color: white;
      font-size: 15px;
      font-weight: 500;
      margin-right: 15px;
    }
  }
}

.history-tag-listing {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 12px;
  margin-bottom: 12px;
  height: min-content;

  h2 {
    color: white;
    margin: 15px 20px 10px 0;
  }

  .history-tag-bottle {
    display: flex;
    align-items: center;
    background: #333;
    padding: 4px 8px;
    border-radius: 8px;
    margin-right: 8px;
    margin-top: 5px;

    p {
      color: $subtitle;
      font-size: 12px;
      font-weight: 500;
      margin-right: 10px;
      border: 1px solid $border;
      border-radius: 6px;
      padding: 2px 3px;
    }

    h4 {
      color: white;
      font-size: 15px;
      font-weight: 500;
    }

    button {
      display: flex;
      align-items: center;
      background: transparent;
      border: 0;
      margin-left: 8px;
    }
  }
}

.history-delay {
  color: #f6ff00;
  margin-top: 10px;

  img {
    margin-right: 8px;
  }

  .explore {
    align-items: flex-end;
    cursor: pointer;
    margin-left: 10px;

    h3 {
      color: white;
      font-size: 13px;
      font-weight: 500;
    }
  }
}

.highcharts-yaxis-labels text {
  fill: #ffffff !important;
}

.el-select-dropdown__item > span,
.el-input__inner {
  color: white !important;
}

.highcharts-navigator-xaxis > text {
  fill: transparent !important;
}

.el-select__selected-item {
  margin-top: 0 !important;
}

@media screen and (max-width: 1350px) {
  .hc-line {
    padding: 17px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .hc {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 17px;
      height: 543px;
      max-width: 850px;
      width: 100%;
      border-radius: 12px;
      border: 1px solid $border;
      background: #333;
      margin-bottom: 30px;
    }

    .product-history-infos-bloc {
      width: 100%;
      margin-left: 0;

      .product-history-chart-options-bloc {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .product-history-chart-choice {
          display: none;
        }

        .high-low-perf,
        .chart-options {
          margin-top: 0;
          width: 45%;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .product-history-compare-bloc {
    flex-wrap: wrap;
  }

  .hc {
    margin-bottom: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    border-radius: 0 !important;

    p {
      margin: 10px;
    }
  }

  .product-history-infos-bloc {
    display: flex;
    flex-wrap: wrap;

    .product-history-chart-options-bloc {
      flex-direction: column;

      .high-low-perf,
      .chart-options {
        border: 0;
        border-radius: 0;
      }

      .high-low-perf,
      .chart-options {
        width: 100% !important;
      }

      .chart-options {
        padding: 20px 0;

        h4 {
          margin-left: 10px;
        }

        div {
          margin-left: 10px;
          margin-right: 10px;

          p {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .divider-chart-options {
    max-width: 90%;
  }

  .price-bloc-data {
    .product-history-compare-bloc {
      margin-left: 10px;

      .product-history-compare {
        margin-bottom: 10px;
      }
    }
  }

  .history-delay {
    flex-wrap: wrap;
    margin-top: 0;

    img {
      display: none;
    }

    p {
      margin-left: 5px;
    }

    .explore {
      margin-left: 5px;
    }
  }

  .select-currency {
    width: 105px !important;
    margin-right: 0 !important;
    margin-left: 0 !important;

    .el-select__wrapper {
      margin-left: 0 !important;
      margin-right: 0 !important;

      .el-select__suffix {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }

    div > div > div {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  .history-tag-listing {
    max-width: 320px;

    h2 {
      margin-left: 5px;
    }
  }

  .high-low-perf {
    height: 190px;
  }

  .el-select__selection {
    width: 31px;
    border: 0;
  }

  // .el-select__wrapper {
  //   padding: 0;
  // }
}
</style>
